/* requires:
polyfill.js
*/

(function () {
  // for active menu highlighting
  const a = document.getElementById('nav').getElementsByTagName('a');
  let loc = window.location.href;
  for (let i = 0; i < a.length; i++) {
    if (a[i].href == loc) {
      a[i].classList.add('is-active');
    }
  }

  document
    .getElementById('nav')
    // .querySelector('ul')
    .insertAdjacentHTML(
      'beforebegin',
      "<button id='menutoggle' aria-label='Menu' role='button'><div class='hamburger-box'><div class='hamburger-inner'></div></div><span>Menu</span></button>"
    );

  const menuToggle = document.getElementById('menutoggle');
  const nav = menuToggle.nextElementSibling;
  const navUl = nav.querySelector('ul');
  const subMenuTriggers = document.querySelectorAll('#nav span.submenu');
  const activeClass = 'is-active';
  const hiddenClass = 'invisible';

  const setAria = (elem, value) => {
    elem.setAttribute('aria-expanded', value);
    elem.setAttribute('aria-pressed', value);
  };

  const toggleSubMenu = (subMenuTrigger, subMenu, action) => {
    subMenuTrigger.setAttribute(
      'aria-expanded',
      subMenuTrigger.getAttribute('aria-expanded') === 'true' || action === 'close' ? 'false' : 'true'
    );

    if (subMenuTrigger.classList.contains(activeClass) || action === 'close') {
      subMenu.classList.remove(activeClass);
      subMenuTrigger.classList.remove(activeClass);
    } else {
      subMenu.classList.remove(hiddenClass); // needs to be before removing adding the is-active class
      subMenuTrigger.classList.add(activeClass);
      subMenu.classList.add(activeClass);
    }
  };

  const hideMenu = () => {
    var el = document.querySelectorAll('#menutoggle, #menutoggle + ul, #nav span.submenu, #nav a.submenu, #nav ul.submenu');
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove(activeClass);
    }
  };

  setAria(menuToggle, false);

  navUl.addEventListener('transitionend', () => {
    if (navUl.offsetHeight === 0) {
      navUl.classList.add(hiddenClass);
      setAria(menuToggle, false);
    } else {
      navUl.classList.remove(hiddenClass);
      setAria(menuToggle, true);
    }
  });

  // toggling the mobile menu
  document.addEventListener(
    'click',
    function (event) {
      const targetEl = event.target;

      // for entire mobile menu
      if (targetEl.closest('#menutoggle')) {
        navUl.classList.add('visible');
        navUl.classList.remove(hiddenClass); // needs to be before removing adding the is-active class
        navUl.classList.toggle(activeClass);
        menuToggle.classList.toggle(activeClass);

        const el = document.querySelectorAll('#nav span.submenu, #nav a.submenu, #nav ul.submenu');
        for (let i = 0; i < el.length; i++) {
          el[i].classList.remove(activeClass);
        }
        event.preventDefault();
      }

      if (!targetEl.closest('#nav') && !targetEl.closest('#menutoggle')) {
        hideMenu();
      }

      // if the #menuToggle is visible
      if (menuToggle.offsetWidth > 0 && menuToggle.offsetHeight > 0) {
        // for menu dropdown
        if (targetEl.closest('#nav span.submenu, #nav a.submenu')) {
          const subMenuTrigger = targetEl.closest('#nav span.submenu, #nav a.submenu');
          const subMenu = subMenuTrigger.nextElementSibling;

          toggleSubMenu(subMenuTrigger, subMenu);
        }

        if (!targetEl.closest('#nav') && !targetEl.closest('#menutoggle')) {
          if (menuToggle.classList.contains(activeClass)) {
            hideMenu();
          }
        }
      }
    },
    false
  );

  let resizeTimer;
  window.addEventListener(
    'resize',
    function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        if (menuToggle.offsetWidth <= 0 && menuToggle.offsetHeight <= 0) {
          hideMenu();
          navUl.classList.remove(hiddenClass);
        } else if (!menuToggle.classList.contains(activeClass)) {
          navUl.classList.add(hiddenClass);
        }
      }, 250);
    },
    false
  );

  // closing the nav or submenu if the tabbed element is no longer the nav or submenu
  let prevTabbedNavLi = null;
  document.addEventListener('keyup', (e) => {
    const targetEl = e.target;
    const currTabbedNav = targetEl.closest('nav');

    if (e.key === 'Tab') {
      const currTabbedNavLi = targetEl.closest('nav > ul > li');

      // tabbing on mobile for main nav
      if (menuToggle.offsetWidth > 0 && menuToggle.offsetHeight > 0) {
        if (!currTabbedNav && menuToggle.classList.contains(activeClass)) {
          hideMenu();
        }
      }
      // tabbing on desktop for submenus
      else {
        if (prevTabbedNavLi !== currTabbedNavLi) {
          const subMenuTrigger = prevTabbedNavLi?.querySelector('span.submenu');
          const subMenu = prevTabbedNavLi?.querySelector('ul.submenu');

          if (subMenuTrigger && subMenu) {
            toggleSubMenu(subMenuTrigger, subMenu, 'close');
          }
        }
      }

      prevTabbedNavLi = currTabbedNavLi;
    }

    // close entire menu on mobile and close dropdown on desktop
    if (e.key === 'Escape') {
      if (currTabbedNav) {
        hideMenu();
      }
    }
  });

  for (let subMenuTrigger of subMenuTriggers) {
    const subMenu = subMenuTrigger.nextElementSibling;
    subMenuTrigger.setAttribute('aria-haspopup', true);

    subMenuTrigger.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        toggleSubMenu(subMenuTrigger, subMenu);
      }
    });

    setAria(subMenuTrigger, false);
    subMenu.classList.add(hiddenClass);

    subMenu.addEventListener('transitionend', () => {
      if (subMenu.offsetHeight === 0) {
        subMenu.classList.add(hiddenClass);
        setAria(subMenuTrigger, false);
      } else {
        subMenu.classList.remove(hiddenClass);
        setAria(subMenuTrigger, true);
      }
    });
  }
})();
