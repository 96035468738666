var revealbar = function (query, _options) {
  function offset(elem) {
    var doc, docElem, rect, win;
    if (!elem) {
      return;
    }
    if (!elem.getClientRects().length) {
      return { top: 0, left: 0 };
    }
    rect = elem.getBoundingClientRect();
    doc = elem.ownerDocument;
    docElem = doc.documentElement;
    win = doc.defaultView;
    return { top: rect.top + win.pageYOffset - docElem.clientTop, left: rect.left + win.pageXOffset - docElem.clientLeft };
  }
  function css(element, selector) {
    if (typeof selector == 'object') {
      for (var key in selector) {
        if (!selector.hasOwnProperty(key)) {
          continue;
        }
        var value = selector[key];
        key = key
          .replace(/-([a-z0-9_])/g, function (string, letter) {
            return letter.toUpperCase();
          })
          .replace('-', '');
        element.style[key] = value;
      }
      return;
    }
    var value = window.getComputedStyle(element).getPropertyValue(selector);
    return value;
  }
  function outerHeight(element) {
    return element.offsetHeight;
  }
  function height(element) {
    if (element == window || element == document) {
      return element.innerHeight;
    }
    var height = css(element, 'height');
    return parseFloat(height);
  }
  var $bar = document.querySelector(query);
  var noop = function () {};
  var options = { onAttach: noop, onDetach: noop, onShow: noop, onHide: noop, bottomOffset: 0 };
  for (var key in _options) {
    if (options.hasOwnProperty(key)) {
      options[key] = _options[key];
    }
  }
  var $window = window,
    $document = document,
    lastPosition = document.documentElement.scrollTop || document.body.scrollTop,
    initialBarPosition = offset($bar).top;

  var scroll = function (e) {
    var currentPosition = document.documentElement.scrollTop || document.body.scrollTop,
      barFullHeight = outerHeight($bar),
      barOffsetHeight = barFullHeight - options.bottomOffset,
      barTopPosition = offset($bar).top;
    if (currentPosition < 0 || currentPosition > height($document) - height($window)) {
      return;
    }
    if (currentPosition < lastPosition) {
      if (currentPosition === 0) {
        options.onAttach();
      } else {
        if (css($bar, 'position') === 'fixed' && currentPosition > barTopPosition) {
          css($bar, { position: 'absolute', top: barTopPosition + 1 + 'px' });
        } else if (css($bar, 'position') === 'absolute' && currentPosition <= barTopPosition) {
          options.onShow();
          css($bar, { position: 'fixed', top: '0px' });
        }
      }
    } else if (currentPosition > lastPosition || (currentPosition === lastPosition && currentPosition !== 0)) {
      if (currentPosition > barTopPosition + barOffsetHeight) {
        options.onDetach();
        options.onHide();
        css($bar, { position: 'fixed', top: -barOffsetHeight + 'px' });
      } else if (currentPosition !== barTopPosition + barOffsetHeight) {
        css($bar, { position: 'absolute', top: barTopPosition + 'px' });
      }
    }
    lastPosition = currentPosition;
  };
  scroll(window.event);
  window.addEventListener('scroll', scroll);
  this.destroy = function () {
    window.removeEventListener('scroll', scroll);
    options.onAttach();
    css($bar, { position: 'absolute', top: initialBarPosition + 'px' });
  };
};

// usage example below
// revealbar("#site-header", {
//   bottomOffset: 40,
//   onDetach: function() {
//     document.body.classList.add("header-fixed");
//   }.bind(this),
//   onAttach: function() {
//     document.body.classList.remove("header-fixed");
//   }.bind(this),
//   onShow: function() {
//     window.revealBarIsVisible = !0
//   },
//   onHide: function() {
//     window.revealBarIsVisible = !1
//   }
// });
